import { forwardRef, useCallback } from 'react';
import ReactPlayer from 'react-player';

import { CardSkeleton } from '@/common/components/Card/shared/Skeletons/CardSkeleton';
import { Box } from '@/common/components/Display/Box';
import { Loader } from '@/common/components/Loader';
import { useFlag } from '@/common/hooks/useFlag';
import { useSimpleTimer } from '@/common/hooks/useSimpleTimer';
import { CardTypes } from '@/common/models/CardTypes';
import { FacebookAppId } from '@/common/utils/Constants';

export const isUrlYouTube = (value: string) => {
  if (!value) return false;
  return (
    value?.indexOf('://youtu.be') !== -1 ||
    value?.indexOf('://www.youtube.com') !== -1
  );
};

interface Props {
  url: string;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  onViewedFor30Seconds?: () => void;
  layout?: 'iframe' | 'inline';
  iframeWrapperWidth?: string;
  onReady?: () => void;
  playing?: boolean;
  hideControls?: boolean;
  allowDownload?: boolean;
  loadVariant?: 'card' | 'loader';
  muted?: boolean;
  loop?: boolean;

  /**
   * String value to pass image URL which will override the preview image
   * Set to boolean to indicate if the default video thumbnail should show
   */
  thumbnail?: string | boolean;
  playsinline?: boolean;
  containerStyle?: React.CSSProperties;
}

// This is typed as any because react-player config types for facebook dont allow optional props.
const getPlayerConfig = (allowDownload?: boolean) => {
  const config: any = {
    file: {
      attributes: {
        onContextMenu: (e) => e.preventDefault(),
        disablePictureInPicture: true
      }
    },
    facebook: {
      appId: FacebookAppId
    }
  };
  if (!allowDownload) {
    config.controlsList = 'nodownload';
  }
  return config;
};

export const VideoPlayer = forwardRef<ReactPlayer, Props>(
  (
    {
      allowDownload,
      hideControls,
      iframeWrapperWidth,
      onEnded,
      onPause,
      onPlay,
      onReady,
      onViewedFor30Seconds,
      url,
      layout = 'iframe',
      thumbnail = false,
      loadVariant = 'card',
      playing = false,
      playsinline = true,
      containerStyle,
      ...rest
    },
    ref
  ) => {
    const { pauseTimer, resumeTimer } = useSimpleTimer({
      seconds: 30,
      onEnded: onViewedFor30Seconds,
      active: playing
    });

    const handlePlay = () => {
      resumeTimer();
      onPlay?.();
    };

    const handlePause = () => {
      pauseTimer();
      onPause?.();
    };

    const handleEnded = () => {
      pauseTimer();
      onEnded?.();
    };

    if (layout === 'iframe') {
      return (
        <Box
          w={iframeWrapperWidth}
          pt={'56.25%'}
          pos={'relative'}
          style={containerStyle}
          overflow="visible"
        >
          <ReactPlayer
            ref={ref}
            url={url}
            controls={!hideControls}
            width="100%"
            height="100%"
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
            pip={false}
            config={getPlayerConfig(allowDownload)}
            onReady={onReady}
            playing={playing}
            light={thumbnail}
            playsinline={playsinline}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0
            }}
            {...rest}
          />
        </Box>
      );
    }

    // non-iframe videos can scale up to the width of the card.
    return (
      <InlineVideoPlayer
        url={url}
        ref={ref}
        loadVariant={loadVariant}
        hideControls={hideControls}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        playing={playing}
        allowDownload={allowDownload}
        thumbnail={thumbnail}
        playsinline={playsinline}
        {...rest}
      />
    );
  }
);

const InlineVideoPlayer = forwardRef<ReactPlayer, Props>(
  (
    {
      allowDownload,
      hideControls,
      onEnded,
      onPause,
      onPlay,
      onReady,
      url,
      thumbnail,
      loadVariant = 'card',
      playing = false,
      playsinline = true,
      muted = false,
      loop = false
    },
    ref
  ) => {
    const ready = useFlag(false);
    const height = ready.value ? '100%' : '0';

    const handleOnReady = useCallback(() => {
      ready.setTrue();
      onReady?.();
    }, [onReady]);

    return (
      <>
        {!ready && (
          <>
            {loadVariant === 'card' && (
              <CardSkeleton w="100%" type={CardTypes.Video} />
            )}
            {loadVariant === 'loader' && <Loader />}
          </>
        )}
        <ReactPlayer
          url={url}
          ref={ref}
          controls={!hideControls}
          width="100%"
          height={height}
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
          onClickPreview={onPlay}
          light={thumbnail}
          pip={false}
          config={getPlayerConfig(allowDownload)}
          onReady={() => handleOnReady()}
          playing={playing}
          playsinline={playsinline}
          muted={muted}
          loop={loop}
        />
      </>
    );
  }
);

export default VideoPlayer;
